<template>
  <v-container class="pa-5 mb-10" fluid>
    <v-row style="width: 100%">
      <v-col cols="12">
        <v-card style="background: #f5f5f5">
          <v-card-title class="pt-10 pl-10">Sales Report by Product
            <v-spacer></v-spacer>
            <v-btn @click="exportTableToCSV('sales_report.csv')" text>
              <v-icon class="pr-5" large color="black">mdi-download </v-icon>
            </v-btn>
            <v-btn text @click="print">
              <v-icon class="pr-5" large color="black">mdi-printer </v-icon>
            </v-btn>
          </v-card-title>
          <v-card style="background: #f5f5f5">
            
            <v-row>
              <v-col cols="4">
                <v-tabs v-model="tab" background-color="transparent" color="primary" grow>
                  <v-tab v-for="item in items" :key="item">
                    {{ item }}
                  </v-tab>
                </v-tabs>
              </v-col>

              <v-col cols="8" class="d-flex flex-row">
                <v-spacer></v-spacer>
                <v-col cols="2">
                  <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="dateto1" label="From Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on" dense style="padding:0px !important"></v-text-field>
                    </template>
                    <v-date-picker v-model="dateto1" @input="menu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2">
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="dateto" label="To Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on" dense style="padding:0px !important"></v-text-field>
                    </template>
                    <v-date-picker v-model="dateto" @input="menu2=false"></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- <v-btn color="white" @click="todate">
                  To Date
                  <v-icon color="black" right>mdi-calendar-month </v-icon>
                </v-btn> -->

                <v-btn text @click="seereport" class="mr-2 font-weight-bold" style="background: gray">
                  Submit
                </v-btn>
              </v-col>
            </v-row>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <v-toolbar-title style="margin-left: -14px" class="mt-2 mb-2 ml-2">
                      Daily Sales Report
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <div class="pr-5 pb-5 pt-5">
                      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" filled rounded dense
                        hide-details></v-text-field>
                    </div> -->
                    <!-- <v-switch
                      v-model="singleExpand"
                      label="Single expand"
                      class="mt-5"
                    ></v-switch>  v-if="item.Brand_name != 'Individual' "-->
                  </v-toolbar>
                </v-card>
                <div id="dataapp">
                  <v-data-table :headers="headers" :loading="loading" :items="Sales" :search="search" :server-items-length="totalItems1" sort-by="id" item-key="id"
                    class="elevation-1" style="background: #f5f5f5; font-size: 10em" hide-default-footer
                    disable-pagination>
                    <template v-slot:top >
                      <v-card-title>
                          <v-spacer></v-spacer>
                          <div class="pr-4 pb-4 pt-2 d-flex ">
                              <div class="search g">
                                  <v-text-field v-model="search_key" label="Search by order id" filled rounded   dense hide-details>
                                  </v-text-field>
                              </div>
                              <div class="icon-search pr-4 pb-5 pt-2">
                                  <v-btn icon style="background: red"><v-icon      @click="searchHandleSalesReport">mdi-magnify</v-icon>
                                  </v-btn>                                                          
                              </div>
                              
                          </div>
                      </v-card-title>
                    </template>
                    <template slot="body.append">
                      <tr>
                        <th class="h4">Totals</th>
                        <th class="h6"></th>
                        <th class="h6"></th>
                        <th class="h6"></th>
                        <th class="h6"></th>
                        <th class="h6"></th>
                        <th class="h6"></th>
                        <th class="h6">{{ sumField("total_quantity") }}</th>
                        <th class="h6">{{ sumField("purchase_price") }}</th>
                        <th class="h6">{{ sumField("total_price") }}</th>
                        <th class="h6">{{ sumField("vat") }}</th>
                        <th class="h6">{{ sumField("discount") }}</th>
                        <th class="h6">{{ sumField("net_sale") }}</th>
                        <th class="h6">{{ sumField("profit") }}</th>
                        <th class="h6">{{ sumField("loss") }}</th>
                      </tr>
                    </template>
                    <template #item.spacification="{ item }">{{ item.product_color }} 
                      {{ item.product_size }}</template>
                    <template #item.warehouse="{ item }">{{ item.warehouse_name }} | 
                      {{ item.shop_name }}</template>
                  </v-data-table>
                </div>
              </v-tab-item>
              <v-tab-item>

                 <!-- data table  -->
                <v-data-table :headers="headersweek" :items="Salesweek" :search="searchweek" :server-items-length="totalItems1" sort-by="id"
                  class="elevation-1" style="background: #f5f5f5; font-size: 10em">
                  <!--
                      :single-expand="singleExpand"
                :expanded.sync="expanded"
                show-expand
                  <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <b>brand ID : </b> {{ item.id }} <br />
                    <b>Name : </b> {{ item.Brand_name }}<br />
                    <b>Loction : </b> {{ item.brand_location }}<br />
                    <b>Quantity : </b> {{ item.spacification }}<br />
                  </td>
                </template> -->
                  <template v-slot:top>
                    <v-toolbar elevation="2" style="background: #f5f5f5">
                      <v-toolbar-title style="margin-left: -14px" class="mt-2 mb-2 ml-2">
                        Weekly Sales Report
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <!-- <div class="pr-5 pb-5 pt-5">
                        <v-text-field v-model="searchweek" append-icon="mdi-magnify" label="Search" filled rounded dense
                          hide-details></v-text-field>
                      </div> -->
                      <!-- <v-switch
                      v-model="singleExpand"
                      label="Single expand"
                      class="mt-5"
                    ></v-switch>  v-if="item.Brand_name != 'Individual' "-->
                    </v-toolbar>
                  </template>
                </v-data-table>

              </v-tab-item>
            </v-tabs-items>
            
          </v-card>
        </v-card>
        <div class="d-flex justify-content-center mt-5">
          <!-- <v-col cols="3" md="3"> -->
          <div class="col-4">
              Total {{ totalItems1 }} records
          </div>
          <!-- </v-col> -->
          <!-- <v-col cols="3" md="3"> -->
          <div class="col-4">
              <v-select dense outlined hide-details v-model="perPage1" label="Items per page" @change="handlePageSizeChange" :items="pageSizes1" >
              </v-select>
              <!-- <span>Show</span>
               @change="handlePageSizeChange($event)" -->
              <!-- <select v-model="perPage" @change="handlePageSizeChange($event)">
                  <option v-for="size in pageSizes" :key="size" :value="size">{{ size }}</option>
              </select> --> 

          </div>
          <!-- </v-col> -->
          <!-- <v-col cols="6" md="3" > -->
          <div class="col-4">
              <v-pagination v-model="currentPage1" :length="totalPages1" total-visible="7" @input="handlePageChange">
              </v-pagination>
          </div>

          <!-- </v-col> -->
      </div>

      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" outlined centered color="black">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
      
      <div class="text-center">
        <v-dialog
          v-model="dialog"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">

          </template>
    
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Privacy Policy
            </v-card-title>
    
            <v-card-text>
              Your request is accecpted.We will send you a Pdf file in your mail. Thanks
            </v-card-text>
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
  </v-container>
</template>


<script>
export default {
  // components: VueJsonToCsv,
  data: () => ({
    snackbar: "",
    dialog: false,
    date: new Date().toISOString().substr(0, 2),
    menu: false,
    text: "",
    perPage1: '10',
    currentPage1: 1,
    totalItems1: 0,
    search_key:null,
    totalPages1: 0,
    pageSizes1: ['10', '30', '50', '100', 'All'],
    menu2: false,
    loading: true,
    dateto1: null,
    dateto: null,
    search: "",
    searchweek: "",
    
    headers: [
      {
        text: "Date",
        align: "start",
        value: "date_added",
      },
      { text: "Order Id", value: "order_id", sortable:true},
      { text: "Product Name", value: "product_name", },
      // { text: "Specifications", value: "spacification", },
      { text: "Color", value: "product_color", },
      { text: "Size", value: "product_size", },
      { text: "Category", value: "category_name" },
      { text: "Warehouse/Shop", value: "warehouse" },
      { text: "Net Quantity", value: "total_quantity" },
      { text: "Purchase", value: "purchase_price" },
      { text: "Gross Sales", value: "total_price" },
      { text: "VAT ", value: "vat" },
      { text: "Discount", value: "discount" },
      { text: "Net Sale", value: "net_sale" },
      { text: "Income", value: "profit" },
      { text: "Loss", value: "loss" },
    ],

    headersweek: [
      {
        text: "Date ",
        align: "start",
        value: "date_added",
      },
      { text: "Order Id", value: "order_id", },          
      { text: "Product Name", value: "product_name", },
      { text: "Color", value: "product_color", },
      { text: "Size", value: "product_size", },
      // { text: "Specifications", value: "spacification", },
      { text: "Brand", value: "brand" },
      { text: "Warehouse/Shop", value: "warehouse" },
      { text: "Net Quantity", value: "total_quantity" },
      { text: "Purchase", value: "purchase_price" },
      { text: "Gross Sales", value: "total_price" },
      { text: "VAT ", value: "vat", },
      { text: "Discount", value: "discount" },
      { text: "Net Sale", value: "net_sale" },
      { text: "Income", value: "profit" },
      { text: "Loss", value: "loss" },
    ],

    Salesweek: [],
    Sales: [],
    contact: {},
    text: "",
    
    snackbar: false,
    tab: null,
    // items: ["Daily Report", "Weekly Report"],
    items: ["Daily Report", ],
  }),

  methods: {
    searchHandleSalesReport(event){
      this.initialize();
    },
    handlePageSizeChange(event) {
            this.perPage1 = (event);
            this.loading = true;
            this.initialize();
        },
        handlePageChange(value) {
           
            this.currentPage1 = value;
            this.loading = true;
            this.initialize();
            window.scrollTo(0, 0);
        },
   async  initialize() {
      let perPage;
      if (this.perPage1 === 'All'){
        this.dialog= true;
        await axios.get (`Cart/sales_report_csv/`).then((response)=>{
          console.log("thread is :", response);
        })
        perPage = this.totalItems1;
       
      }
      else{
          perPage = this.perPage1;
      }
      this.loading = true;
      axios.get(`Cart/sales_report/?page=${this.currentPage1}&size=${perPage}&fromDate=${this.dateto1}&toDate=${this.dateto}&search_key=${this.search_key}`).then((response) => {
        if (response.status === 200){
          
          this.Sales = response.data.results;
          
          this.totalItems1 = response.data.count;
          this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
          this.loading = false;
        }
        

      });
    },
    sumField(key) {
      // sum data in give key (property)
      return this.Sales.reduce((a, b) => a + (b[key] || 0), 0);
    },
    
    downloadCSV(csv, filename){
            var csvFile;
            var downloadLink;
           
            csvFile = new Blob([csv], {type: 'text/csv'});
            downloadLink = document.createElement("a");
            downloadLink.download = filename;
            downloadLink.href = window.URL.createObjectURL(csvFile);
            downloadLink.style.display = "none";

            document.body.appendChild(downloadLink);
            downloadLink.click();
    },
    exportTableToCSV(filename) {
      
          
          var csv = [];
          var rows = document.querySelectorAll(" #dataapp table tr");
        
          for(var i=0; i<rows.length; i++) {
           var row = [], cols = rows[i].querySelectorAll("td, th");
           for( var j=0; j<cols.length; j++)
              row.push(cols[j].innerText);
           csv.push(row.join(","));
          } 
         
          this.downloadCSV(csv.join("\n"), filename);
       },
    
    print() {
      var divToPrint = document.getElementById("dataapp");
      // var newWin = window.open("");
      // newWin.document.write(divToPrint.outerHTML);
      // newWin.print();

      //newWin.close();

      var style = "<style>";
      style = style + "table {width: 100%;font: 17px Calibri;}";
      style =
        style +
        "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
      style = style + "padding: 2px 3px;text-align: center;}";
      style = style + "</style>";

      var win = window.open("", "", "height=1900,width=1900");
      win.document.write(style); //  add the style.
      win.document.write(divToPrint.outerHTML);
      win.document.close();
      win.print();
    },

    fromdate() { },
    seereport() {
      // console.log('this from  date is :', this.dateto1);
      // console.log('this to  date is :', this.dateto);
      this.initialize();
    },
    todate() { },
  },
  mounted() {
    this.initialize();
  },
};
</script>




<style >
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>